@import './variable';
@import './typography';

.controls-screen {
    .portal-name-text {
        color: $white;
    }

    .portal-name-skeleton {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .portal-status-text {
        padding-bottom: 2rem;

        &.success {
            color: $green;
        }
    }

    .gradient-circle1 {
        position: absolute;
        background: linear-gradient(158.43deg, rgba(19, 32, 82, 0.4) 8.71%, rgba(9, 15, 38, 0.4) 71.08%);
        width: 23rem;
        height: 23rem;
        border-radius: 20rem;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 19.8rem;
    }

    .gradient-circle2 {
        position: absolute;
        left: 0;
        right: 0;
        top: 22.8rem;
        background: linear-gradient(158.43deg, #132052 8.71%, #090f26 71.08%);
        border-radius: 20rem;
        width: 16.875rem;
        height: 16.875rem;
        margin: 0 auto;
    }

    .controls-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 19.8rem;
        width: 23rem;
        height: 23rem;
        margin: 0 auto;

        .inner-controls-container {
            width: 18.75rem;
            height: 23.562rem;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            margin: 0 auto;
            gap: 2rem;

            > .align-bottom {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
            }
        }
    }
}
