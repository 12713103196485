@import './variable';
@import './typography';

.volume-slider-container {
    .slidecontainer {
        width: calc(100% - 7.5rem);
        align-self: center;
        .rangeslider {
            &.disabled {
                pointer-events: none;
                opacity: 0.7;
                z-index: 999;
            }
            &.rangeslider-horizontal {
                height: 6px;
                border-radius: 0px !important;
                .rangeslider__fill {
                    background-color: $turquoise;
                    box-shadow: none !important;
                    border-radius: 0 !important;
                }
                .rangeslider__handle {
                    width: 1.2rem;
                    height: 1.2rem;
                    border: none !important;
                    box-shadow: none !important;
                    &::after {
                        width: 0.375rem;
                        height: 0.375rem;
                        top: 0.375rem;
                        left: 0.375rem;
                        background-color: $turquoise;
                        box-shadow: none !important;
                    }
                }
            }
        }
    }
}
