@import './variable';
@import './typography';

.header {
    width: 100%;
    .header-contents {
        display: flex;
        align-items: center;
        padding: 1.5rem 1rem;
        margin-bottom: 1.25rem;
        justify-content: stretch;
    }
    .login-header {
        font: normal $weight-700 1.875rem/2.75rem $font-1;
        color: $white;
        margin-top: 0.9rem;
        margin-left: 4.9rem;
    }

    .back-btn-container {
        z-index: 9;
        height: 2.75rem;
        width: 2.75rem;
    }

    .logo-container {
        width: calc(100% - 88px);
        text-align: center;
    }

    .logo {
        height: 1rem;
    }

    .logout-btn-container {
        position: absolute;
        margin: 1.65rem 1rem 1.65rem 0rem;
        right: 0;
    }
}
