@import './variable';
@import './typography';

.card.card-with-backdrop {
    width: 100%;

    border-radius: 1rem;
    background-color: transparent;
    border: none !important;
    color: $white;
    margin-bottom: 1.5rem;

    .card-body {
        padding: 0;
    }

    .main {
        background-color: $bg-300;
        border-radius: 1rem;
        padding: 1.5rem 1.688rem;

        .switch-portal {
            border-radius: 1rem;
            height: 1.5rem;
            width: 1.5rem;

            &:hover,
            &:active {
                background-color: $bg-200;
            }
        }

        &.sm i {
            position: relative;
            top: calc(50% - 9px);
        }
    }

    .backdrop {
        position: absolute;
        background-color: $bg-200;
        height: 1rem;
        width: calc(100% - 2rem);
        left: 1rem;
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }
}

.card.card-without-backdrop {
    max-width: 100%;
    width: 21.5rem;
    margin: 0 auto;
    margin-top: 1.875rem !important;
    background-color: $bg-300;
    border-radius: 1rem;
    border: none !important;
    color: $white;

    .card-body {
        padding: 1.875rem 2.188rem;
        text-align: center;
    }
}
