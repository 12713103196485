$font-1: 'Fira Sans', sans-serif;
$font-2: 'Source Sans Pro', sans-serif;

$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;
$weight-900: 900;
